<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main">
                        <h4 class="text-capitalize breadcrumb-title">Tagihan</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <!-- @if($level == "admin")
            <a class="btn btn-primary btn-default btn-squared text-capitalize px-30" href="#" style="margin:5px;display:inline-block" data-toggle="modal" data-target="#tambah" href="#">Edit Paket</a>
            <a class="btn btn-primary btn-danger btn-squared text-capitalize px-30" style="display:inline-block" href="{{ url('billing/edit/delete/'.$package->id) }}">Hapus Paket</a>

            <div class="modal fade new-member" id="tambah" role="dialog" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content  radius-xl">
                        <div class="modal-header">
                            <h6 class="modal-title fw-500" id="staticBackdropLabel">Edit Paket</h6>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span data-feather="x"></span>
                            </button>
                        </div>
                        <div class="modal-body">
                            @if($package->lisensi == "1" && $package->duration)
                            <form action="{{ url('billing/edit/'.$package->id) }}" method="post">
                                @csrf
                                <label for="">Nama Paket</label>
                                <input type="text" class="form-control" name="name" value="{{ $package->name }}" required>
                                <br>
                                <label for="">Lisensi</label>
                                <input type="number" class="form-control" name="lisensi" value="{{ $package->lisensi }}" readonly required>
                                <br>
                                <label for="">Kuota Pesan</label>
                                <input type="number" class="form-control" name="message_quota" value="{{ $package->message_quota }}" required>
                                <br>
                                <label for="">Max Device</label>
                                <input type="number" class="form-control" name="max_device" value="{{ $package->max_device }}" required>
                                <br>
                                <label for="">Max CS</label>
                                <input type="number" class="form-control" name="max_cs" value="{{ $package->max_cs }}" required>
                                <br>
                                <label for="">Max Campaign</label>
                                <input type="number" class="form-control" name="max_campaign" value="{{ $package->max_campaign }}" required>
                                <br>
                                <label for="">Max Order Online</label>
                                <input type="number" class="form-control" name="max_order_online" value="{{ $package->max_order_online }}" required>
                                <br>
                                <label for="">Max Link Rotator</label>
                                <input type="number" class="form-control" name="max_link_rotator" value="{{ $package->max_link_rotator }}" required>
                                <br>
                                <label for="">Max Chat Widget</label>
                                <input type="number" class="form-control" name="max_chat_widget" value="{{ $package->max_chat_widget }}" required>
                                <br>
                                <label for="">Durasi (hari)</label>
                                <input type="number" class="form-control" name="duration" value="{{ $package->duration }}" required>
                                <br>
                                <label for="">Harga (Rupiah)</label>
                                <input type="number" class="form-control" name="price" value="{{ (int)$package->price }}" required>
                                <br>
                                <input name="hide" class="checkbox" type="checkbox" id="check-1" @if($package->hide == "1") checked @endif>
                                <label for="check-1">
                                    <span class="checkbox-text">Sembunyikan</span>
                                </label>
                                <br>
                                <button type="submit" class="btn btn-success btn-block"> Edit </button>
                            </form>
                            @endif

                            @if($package->lisensi == "1" && $package->duration == null)
                            <form action="{{ url('billing/edit/'.$package->id) }}" method="post">
                                @csrf
                                <label for="">Nama Paket</label>
                                <input type="text" class="form-control" name="name" value="{{ $package->name }}" required>
                                <br>
                                <label for="">Lisensi</label>
                                <input type="number" class="form-control" name="lisensi" value="{{ $package->lisensi }}" readonly required>
                                <br>
                                <label for="">Kuota Pesan</label>
                                <input type="number" class="form-control" name="message_quota" value="{{ $package->message_quota }}" required>
                                <br>
                                <label for="">Max Device</label>
                                <input type="number" class="form-control" name="max_device" value="{{ $package->max_device }}" required>
                                <br>
                                <label for="">Max CS</label>
                                <input type="number" class="form-control" name="max_cs" value="{{ $package->max_cs }}" required>
                                <br>
                                <label for="">Max Campaign</label>
                                <input type="number" class="form-control" name="max_campaign" value="{{ $package->max_campaign }}" required>
                                <br>
                                <label for="">Max Order Online</label>
                                <input type="number" class="form-control" name="max_order_online" value="{{ $package->max_order_online }}" required>
                                <br>
                                <label for="">Max Link Rotator</label>
                                <input type="number" class="form-control" name="max_link_rotator" value="{{ $package->max_link_rotator }}" required>
                                <br>
                                <label for="">Max Chat Widget</label>
                                <input type="number" class="form-control" name="max_chat_widget" value="{{ $package->max_chat_widget }}" required>
                                <br>
                                <label for="">Harga (Rupiah)</label>
                                <input type="number" class="form-control" name="price" value="{{ (int)$package->price }}" required>
                                <br>
                                <input name="hide" class="checkbox" type="checkbox" id="check-1" @if($package->hide == "1") checked @endif>
                                <label for="check-1">
                                    <span class="checkbox-text">Sembunyikan</span>
                                </label>
                                <br>
                                <button type="submit" class="btn btn-success btn-block"> Edit </button>
                            </form>
                            @endif

                            @if($package->lisensi > "1" && $package->duration)
                            <form action="{{ url('billing/edit/'.$package->id) }}" method="post">
                                @csrf
                                <label for="">Nama Paket</label>
                                <input type="text" class="form-control" name="name" value="{{ $package->name }}" required>
                                <br>
                                <label for="">Lisensi</label>
                                <input type="number" class="form-control" name="lisensi" value="{{ $package->lisensi }}" required>
                                <br>
                                @php 
                                if($package->data){
                                    $allow = unserialize($package->data);
                                }else{
                                    $allow = [];
                                }
                                @endphp
                                <label>Allow Paket</label>
                                <select name="data[]" id="data" class="form-control" multiple required>
                                    @foreach($package_list as $p)
                                        @if($p->lisensi == "1" && $p->duration)
                                        <option value="{{$p->id}}" @if(in_array($p->id, $allow)) selected @endif>{{ $p->name }}</option>
                                        @endif
                                    @endforeach
                                </select>
                                <br>
                                <br>
                                <label for="">Kuota Pesan</label>
                                <input type="number" class="form-control" name="message_quota" value="{{ $package->message_quota }}" required>
                                <br>
                                <label for="">Max Device</label>
                                <input type="number" class="form-control" name="max_device" value="{{ $package->max_device }}" required>
                                <br>
                                <label for="">Max CS</label>
                                <input type="number" class="form-control" name="max_cs" value="{{ $package->max_cs }}" required>
                                <br>
                                <label for="">Max Campaign</label>
                                <input type="number" class="form-control" name="max_campaign" value="{{ $package->max_campaign }}" required>
                                <br>
                                <label for="">Max Order Online</label>
                                <input type="number" class="form-control" name="max_order_online" value="{{ $package->max_order_online }}" required>
                                <br>
                                <label for="">Max Link Rotator</label>
                                <input type="number" class="form-control" name="max_link_rotator" value="{{ $package->max_link_rotator }}" required>
                                <br>
                                <label for="">Max Chat Widget</label>
                                <input type="number" class="form-control" name="max_chat_widget" value="{{ $package->max_chat_widget }}" required>
                                <br>
                                <label for="">Durasi (hari)</label>
                                <input type="number" class="form-control" name="duration" value="{{ $package->duration }}" required>
                                <br>
                                <label for="">Harga (Rupiah)</label>
                                <input type="number" class="form-control" name="price" value="{{ (int)$package->price }}" required>
                                <br>
                                <input name="hide" class="checkbox" type="checkbox" id="check-1" @if($package->hide == "1") checked @endif>
                                <label for="check-1">
                                    <span class="checkbox-text">Sembunyikan</span>
                                </label>
                                <br>
                                <button type="submit" class="btn btn-success btn-block"> Edit </button>
                            </form>
                            @endif
                        </div>
                    </div>
                </div>
            </div>
            @endif -->
            <div class="cartPage  global-shadow border pr-sm-30 pl-sm-30  p-15 py-sm-30 bg-white radius-xl w-100 mb-30">
                <div class="row justify-content-center">
                    <div class="cus-xl-9 col-12 ">
                        <div class="product-cart mb-sm-0 mb-20">
                            <div class="table-responsive">
                                <table id="cart" class="table table-borderless table-hover">
                                    <thead>
                                        <tr class="product-cart__header">
                                            <th scope="col">Product</th>
                                            <th scope="col">Harga</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col" class="text-center">total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="Product-cart-title">
                                                <div class="media  align-items-center">
                                                    <div class="media-body">
                                                        <h5 class="mt-0">{{ packages.name }} ({{ packages.duration }} Hari)</h5>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="price">
                                                {{ formatRupiah(packages.price) }}
                                            </td>
                                            <td>
                                                <!-- Start: Product Quantity -->
                                                <div class="quantity product-cart__quantity">
                                                    1
                                                </div>
                                                <!-- End: Product Quantity -->
                                            </td>
                                            <td class="text-center subtotal">
                                                {{ formatRupiah(packages.price) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table><!-- End: table -->
                                <br><br>
                            </div>
                        </div><!-- End: .product-cart-->
                    </div><!-- End: .cus-xl-9 -->
                    <div class="cus-xl-3 col-md-12  ">
                        <div class="card order-summery bg-normal p-sm-25 p-15 order-summery--width ">
                            <div class="card-header border-bottom-0 p-0 pb-25">
                                <h5 class="fw-500">Ringkasan Order</h5>
                            </div>
                            <div class="card-body bg-white px-sm-25 px-20">
                                <div class="total">
                                    <div class="subtotalTotal">
                                        Subtotal:
                                        <span>{{ formatRupiah(packages.price) }}</span>
                                    </div>
                                    <div class="taxes">
                                        discount:
                                        <span id="discount">{{ formatRupiah(coupon.discount) }}</span>
                                    </div>
                                </div>
                                <div class="promo-code">
                                    <label for="exampleInputEmail1">Kode Kupon :</label>
                                    <div class="d-flex align-items-center">
                                        <input style="max-width:100% !important" type="text" class="form-control"
                                            v-model="coupon.code" />
                                        <button v-on:click="checkCouponCode" class="btn btn-outline-success">Apply</button>
                                    </div>
                                    <b v-if="coupon.status == 'loading'" class="text-primary" style="font-size:12px;" id="coupon-message">Memverifikasi Kode Kupon...</b>
                                    <b v-else-if="coupon.status == 'invalid'" class="text-danger" style="font-size:12px;" id="coupon-message">{{ coupon.message }}</b>
                                    <b v-else-if="coupon.status == 'valid'" class="text-success" style="font-size:12px;" id="coupon-message">{{ coupon.message }}</b>
                                </div>
                                <div class="total-money d-flex justify-content-between">
                                    <h6>Total :</h6>
                                    <h5 id="total-final">{{ formatRupiah(total) }}</h5>
                                </div>
                                <div class="total-money d-flex justify-content-between">
                                    <h6>Saldo :</h6>
                                    <h5>{{ formatRupiah(user_balance) }}</h5>
                                </div>
                                <button v-if="user_balance >= total" v-on:click="buyPackage" id="btn-bayar"  class="checkout btn-secondary content-center w-100 btn-lg mt-20">Bayar Sekarang<i class="las la-arrow-right"></i></button>
                                <button v-else class="btn btn-dark btn-squared btn-block w-100 btn-lg mt-20" disabled>Saldo Tidak Cukup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="packages.webhook_media_quota > 0" class="cus-xl-12 col-md-12 mt-5 ">
							<div class="card order-summery bg-normal p-sm-25 p-15 order-summery--width ">
								<div class="card-header border-bottom-0 p-0 pb-25">
                                <h5 class="fw-500">Pilih Device</h5>
							</div>
							<div class="card-body bg-white px-sm-25 px-20">
								
									<multiselect v-model="objek_device" 
									placeholder="Pilih device" :custom-label="nameWithHP" label="nama" track-by="id" :options="device"
									:multiple="false"></multiselect>
								
							</div>
						</div>
				</div>
            </div>
        </div>
    </div>
</template>

<script>
    //import SendSingle from "@/components/forms/outgoing/SendSingle.vue";
    // import moment from 'moment'
    import Multiselect from 'vue-multiselect'
    export default {
        components: {
            //SendSingle,
            //SendBlast
            Multiselect,
        },
        data() {
            return {
                isLoading: true,
                packages: {},
                user_balance: 0,
                discount: 0,
                total: 0,
                coupon: {
                    status: "",
                    message: "",
                    code: "",
                    data: {},
                    discount: 0,
                },
                device: [],
				objek_device:null
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        async mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            let id = this.$route.params.id
            await this.fetchPackage(id)
            await this.fetchUser()
            await this.countTotal()
            if(this.packages.webhook_media_quota > 0){
				await this.getDevice()
			}
        },
        methods: {
            nameWithHP ({ name, no_hp }) {
				return `${name} (${no_hp})`
			},
            async buyPackage() {
                try {
                    if(this.packages.webhook_media_quota > 0){
                        if(this.objek_device == null){
                            this.$toast.error("Harap pilih device")
                        }else{
                            await this.$store.dispatch('package_buy', { id: parseInt(this.$route.params.id), code: this.coupon.code, id_device:parseInt(this.objek_device.id) })
                            
                            this.$toast.success("Berhasil membeli paket")
                            this.$router.push("/billing")
                        }
                    }else{
                        let beli =	await this.$store.dispatch('package_buy', { id: parseInt(this.$route.params.id), code: this.coupon.code })
                        console.log(beli)
                        this.$toast.success("Berhasil membeli paket")
                        this.$router.push("/billing")
                    }
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/billing")
                }
            },
            async fetchUser() {
                try {
                    let user_data = await this.$store.dispatch('user_detail')
                    this.user_balance = user_data.data.data.user.balance
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/billing")
                }
            },
            async fetchPackage(id) {
                this.isLoading = true
                try {
                    let package_detail = await this.$store.dispatch('package_detail', { id: id })
                    this.packages = package_detail.data.data.package
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/billing")
                }
                this.isLoading = false
            },
            formatRupiah(numb) {
                const format = numb?.toString().split('').reverse().join('');
                const convert = format?.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert?.join('.').split('').reverse().join('')
                return rupiah
            },
            async getDevice() {
                let res = await this.$store.dispatch("device_list_all", "all");
                this.device = res.data.data.devices
				
            },
            countTotal(){
                this.total = parseInt(this.packages.price) - parseInt(this.discount)
            },
            async checkCouponCode(){
                this.total = parseInt(this.packages.price)
                this.coupon.status = "loading"
                try {
                    let coupon_detail = await this.$store.dispatch('coupon_check', { code: this.coupon.code })
                    this.coupon.status = "valid"
                    this.coupon.data = coupon_detail.data.data.coupon
                    await this.calculateDiscount()
                } catch (err) {
                    this.coupon.status = "invalid"
                    this.coupon.message = "Kode Kupon Tidak Tersedia"
                }
            },
            calculateDiscount(){
                if(this.coupon.data.mode == 1){
                    //DISCOUNT NOMINAL
                    this.coupon.discount = this.coupon.data.discount
                }else if(this.coupon.data.mode == 2){
                    this.coupon.discount = (this.total / 100) * this.coupon.data.discount
                }

                this.coupon.message = "Kode Kupon Tersedia, Anda Mendapat Diskon Sebesar " + this.formatRupiah(this.coupon.discount)

                //EDIT TOTAL
                if(this.total - this.coupon.discount <= 0){
                    this.total = 0
                }else{
                    this.total = this.total - this.coupon.discount
                }
            }
        },
    };
</script>

<style scoped>
    .order-summery {
        border-radius: 20px;
        border: 1px solid #f1f2f6;
        padding: 1.5625rem !important;
        background-color: #f8f9fb !important;
    }

    .order-summery .card-body {
        border-radius: 20px;
        padding: 30px 25px;
        box-shadow: 0px 10px 30px #9299b81a;
    }
</style>
