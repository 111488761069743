<template>
    <div>
        <center>
            <h4>Withdraw Saldo</h4>
        </center>
        <br>
        <form v-if="isCanWithdraw" v-on:submit.prevent="requestWithdraw">
            <div class="alert alert-info" role="alert">
                Saldo yang anda bisa withdraw adalah {{ formatRupiah(form.max) }}
            </div>
            <br>
            <label> Nominal (Rupiah)</label>
            <money class="form-control" v-model="form.nominal" v-bind="money"></money>
            <b v-if="form.low" class="text-danger" style="font-size:12px">Nominal Withdraw Minimal adalah Rp 20.000</b>
            <b v-if="form.high" class="text-danger" style="font-size:12px">Nominal Withdraw Maksimal adalah {{ formatRupiah(form.max) }}</b>
            <br>
            <br>
            <label for="">Bank</label>
            <input type="text" v-model="form.bank" class="form-control">
            <br>
            <label for="">Nama Rekning</label>
            <input type="text" v-model="form.namaRekening" class="form-control">
            <br>
            <label for="">Nomor Rekening</label>
            <input type="text" v-model="form.nomorRekening" class="form-control">
            <br>
            <p>Setiap withdraw akan dikenakan biaya administrasi sebesar Rp. 6.500</p>
            <button type="submit" :disabled="form.low || form.high" class="btn btn-secondary btn-block">Proses</button>
        </form>
        <div v-else>
            <div class="alert alert-danger" role="alert">
                Anda tidak bisa withdraw karena saldo yang dapat di withdraw kurang dari Rp 20.000
            </div>
        </div>
    </div>
</template>


<script>
    import {
        Money
    } from 'v-money'

    export default {
        props: ['withdrawableBalance'],
        components: {
            Money
        },
        data() {
            return {
                isCanWithdraw: true,
                form: {
                    nominal: 0,
                    bank: "",
                    namaRekening: "",
                    nomorRekening: "",
                    low: true,
                    high: false,
                    min: 20000,
                    max: 0,
                },
                money: {
                    thousands: '.',
                    precision: 0,
                }
            };
        },
        watch: {
            form: {
                handler(val) {
                    if (val.nominal < this.form.min) {
                        val.high = false
                        val.low = true
                    } else if (val.nominal >= this.form.min && val.nominal <= this.form.max) {
                        val.low = false
                        val.high = false
                    } else {
                        val.high = true
                        val.low = false
                    }
                },
                deep: true
            },
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.form.max = this.withdrawableBalance

            if(this.form.max < 20000){
                this.isCanWithdraw = false
            }
        },
        methods: {
            async requestWithdraw(){
                try{
                    let requestWithdraw = await this.$store.dispatch('withdraw_request', this.form)
                    this.$toast.success(requestWithdraw.data.message)
                    this.$root.$emit("toggle_modal", "withdraw")
                    this.$root.$emit("refresh_data", "user")
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            formatRupiah(numb){
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
        },
    };
</script>