<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
            enabled: true,
            dropdownAllowAll: false,
            perPageDropdown: [10, 25, 50, 100],
          }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'action'">
                    <a v-if="props.row.status == 'pending' && props.row.mode == 'topup' && props.row.checkout_url" :href="props.row.checkout_url" class="btn btn-primary px15" style="margin: 5px" target="_blank">
                        Bayar
                    </a>
                </span>
                <span v-else-if="props.column.field == 'created_at'">
                    {{ convertTimezone(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if="props.column.field == 'keterangan'">
                    <span v-if="props.row.mode == 'topup'" class="badge badge-success">Top Up ({{ props.row.method }})</span>
                    <span v-if="props.row.mode == 'pay'" class="badge badge-danger">Pembelian Paket</span>
                    <span v-if="props.row.mode == 'pay_referral'" class="badge badge-success">Pembelian Paket {{ props.row.metadata}} </span>
                    <span v-if="props.row.mode == 'referral'" class="badge badge-success">Referral</span>
                    <span v-if="props.row.mode == 'withdraw'" class="badge badge-danger">Withdraw</span>
                </span>
                <span v-else-if="props.column.field == 'nominal'">
                    {{ formatRupiah(props.formattedRow[props.column.field]) }}
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'success'" class="badge badge-success">Sukses</span>
                    <span v-if="props.row.status == 'pending'" class="badge badge-warning text-white">Pending</span>
                    <span v-if="props.row.status == 'failed'" class="badge badge-danger text-white">Gagal</span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Tanggal Transaksi",
                            field: "created_at",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Keterangan",
                            field: "keterangan",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Nominal",
                            field: "nominal",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Status",
                            field: "status",
                            filterOptions: {
                                enabled: false,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            convertTimezone(utc) {
                return moment.unix(utc / 1000).format("YYYY-MM-DD HH:mm:ss")
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    this.table.rows = response.data.data.transactions;
                } catch (err) {
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("transaction_list", param);
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>